import React from 'react';
import NativeVideoWrapper from '../../components/NativeVideoWrapper/NativeVideoWrapper';
import ProjectPage from '../../components/ProjectPage/ProjectPage';
import SimpleEntryGallery from '../../components/SimpleEntryGallery/SimpleEntryGallery';
import Spacer from '../../components/Spacer/Spacer';
// import './ProjectPageMajesticIreland.scss';
import videoWebmHD from '../../content/images/majestic-ireland/majestic-ireland-homepage-loop-hd.webm';
import videoMp4 from '../../content/images/majestic-ireland/majestic-ireland-homepage-loop.mp4';
import videoPoster from '../../content/images/majestic-ireland/majestic-ireland-screenshot-homepage-desktop-video-poster.jpg';
import { useMajesticIrelandInfo } from '../../content/projects/majestic-ireland.project';
import { PageComponent } from '../../types/gatsby.types';

type ProjectPageMajesticIrelandProps = {}

const ProjectPageMajesticIreland: PageComponent<ProjectPageMajesticIrelandProps> = props => {
  const metaInfo = useMajesticIrelandInfo();
  return <ProjectPage
    {...props} // always include
    project={metaInfo}
  >
    <NativeVideoWrapper
      id="MajesticIrelandHomepageVideoLoop"
      className="MajesticIrelandHomepageVideoLoop"
      src={[videoWebmHD, videoMp4]}
      poster={videoPoster}
      width={1280}
      height={720}
      onlyLoadWhenVisible
      rounded
    />
    <Spacer size="pageMargin" />
    <SimpleEntryGallery 
      entry={metaInfo}
      range={[1, Infinity]}    
      mobileSlidesPosition={0}
    />
  </ProjectPage>
}

export default ProjectPageMajesticIreland;